.bannerContainer__topBanner {
  margin-top: -32px;
  width: calc(100vw - 8px) !important;
  height: auto !important;
}

.bannerContainer__topBanner > div {
  max-width: inherit;
}

.bannerContainer__topBanner > div > div {
  max-width: inherit !important;
}

.bannerContainer__topBanner--mobile {
  margin-top: -44px;
  width: 100vw !important;
  height: auto !important;
}
